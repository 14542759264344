<template>
  <div class="card">
    <div class="card-header">
      <i :class="icon"></i> {{ title }}
    </div>            
    <div class="card-body">     
      <div class="text-center mt-3">
        <img class="img-fluid img-responsive" width="75%" height="75%" src="../assets/logo_deportes.jpeg" alt="Logo">
      </div>  
    </div>
  </div>
</template>

<script>

export default {
  name: 'LogoInicio', 
  data() {
    return {
      title:'',
      icon:''
    }
  },
  created() {
    this.title = process.env.VUE_APP_SUBTITLE;
    this.icon = process.env.VUE_APP_ICON;
  }
}
</script>

