<template>
  <div class="card">
    <div class="card-header">
      <i class="fa fa-key"></i> Historial de Accesos
    </div>            
    <div class="card-body">  
      <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(item, index) in accesos" :key="index">                  
          <small class="text-primary">{{ item.navegador }} / {{ item.so }} </small> <br>
          <small>IP: {{item.ip}}</small>
          <span class="badge bg-success rounded-pill">{{formatoFecha(item.fecha)}} {{formatoHora(item.fecha)}}</span> 
        </li>              
      </ul>
    </div>
  </div> 
</template>

<script>
import Loading from '@/components/Loading';
import moment from 'moment';

export default {
  name: 'Historial',
  data() {
    return {
      cargando:false,
      accesos: []
    }
  },
  components: {
    Loading    
  },
  
  methods: {
    async listarAccesos() {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/historialAccesos`);
        this.accesos = resultados.data.contenido; 
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },   
    formatoFecha(fecha){
      return moment(fecha).format('DD/MM/YYYY');
    },
    formatoHora(fecha){
      return moment(fecha).format('HH:mm:ss');
    },
  },  
  created(){    
    this.listarAccesos();
  }
}
</script>

