<template>
  <div>
    <div class="card ">            
      <div class="card-header">
        Órdenes Diarias
      </div>
      <div class="card-body text-white bg-info">  
        <h3><i class="fa fa-list-alt"></i> {{total}} </h3>
      </div>
    </div> 

    <Loading v-show="cargando" />
  </div>
</template>

<script>
import Loading from '@/components/Loading';

export default {
  name: 'TotalOrdenes',
  data() {
    return {
      cargando:false,
      total: 0
    }
  },
  components: {
    Loading    
  },
  
  methods: {
    async listarTotal() {
      this.cargando = true;      
      try {              
        let resultados = await this.axios.get(`/totalOrdenes`);
        this.total = resultados.data.contenido[0].total; 
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        console.log(error);
      }
    },
  },  
  created(){
    this.listarTotal();
  }
}
</script>

