<template>
  <div class="mt-2 container">
    <div class="row" v-if="$store.state.usuarioDB.rol==2">
      <div class="col-lg-6">
        <RecaudacionOrdenes></RecaudacionOrdenes>
      </div>
      <div class="col-lg-6">
        <TotalOrdenes></TotalOrdenes>
      </div>        
    </div>
    <div class="row" v-if="$store.state.usuarioDB.rol==3">
      <div class="col-lg-6">
        <RecaudacionFacturas></RecaudacionFacturas>
      </div>
      <div class="col-lg-6">
        <TotalFacturas></TotalFacturas>
      </div> 
    </div>
    <div class="row" v-if="$store.state.usuarioDB.rol==4">
      <div class="col-lg-3">
        <RecaudacionOrdenes></RecaudacionOrdenes>
      </div>
      <div class="col-lg-3">
        <TotalOrdenes></TotalOrdenes>
      </div>
      <div class="col-lg-3">
        <RecaudacionFacturas></RecaudacionFacturas>
      </div>
      <div class="col-lg-3">
        <TotalFacturas></TotalFacturas>
      </div>     
    </div>
    <div class="row mt-3">
      <div class="col-lg-6">
        <LogoInicio></LogoInicio>
      </div>
      <div class="col-lg-6">
        <Historial></Historial>
      </div>
    </div>

  </div>
  
</template>

<script>
import Historial from '../components/Historial.vue';
import LogoInicio from '../components/LogoInicio.vue';
import RecaudacionOrdenes from '../components/RecaudacionOrdenes.vue';
import TotalOrdenes from '../components/TotalOrdenes.vue';
import TotalFacturas from '../components/TotalFacturas.vue';
import RecaudacionFacturas from '../components/RecaudacionFacturas.vue';

export default {
  name: 'inicio',
  components: {
    Historial,
    LogoInicio,
    RecaudacionOrdenes,
    TotalOrdenes,
    TotalFacturas,
    RecaudacionFacturas
}
}
</script>

